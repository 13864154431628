@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-color: rgb(31, 31, 31);
  margin: 0;
  font-family: "Kallisto" !important;
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Kallisto";
  src: url("/public/fonts/Kallisto/Kallisto\ Medium.otf");
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(151, 150, 150);
}

.tooltip .tooltiptext {
  visibility: none;
  width: 120px;
  background-color: rgb(26, 26, 26);
  color: #fff;
  text-align: center;
  border-radius: 50px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  bottom: 135%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/*CSS Loading Customize*/
.center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.ring {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 6px 2px red;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 1px 6px 2px red;
  }
}

.ring:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.avatar > span {
  color: #737373;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: text 3s ease-in-out infinite;
}

@keyframes text {
  50% {
    color: black;
  }
}
